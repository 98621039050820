import React from "react";
import { graphql } from "gatsby";
import styles from "../css/single-blog.module.css";

const subTemplate = ({ data }) => {
  return (
    // <Layout>
    <section className={styles.blog}>
      <div className={styles.center}>
        empty
        {/* {data.productList.edges.map(({ node }) => {
          return <ProductCard key={node.id} product={node} />;
        })} */}
      </div>
    </section>
    // </Layout>
  );
};

export const query = graphql`
  query getListProduct($slugSub: String!) {
    productList: allContentfulSubCategory(
      filter: { slugSubCategory: { eq: $slugSub } }
    ) {
      edges {
        node {
          titleSubCategory
          ref: productsRef {
            title: titleProduct
            slug: slugProduct
            id: contentful_id
            imageProduct {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   query getSubCategory($slugSektor: String!) {
//     subCateg: allContentfulSubCategory(
//       filter: { category: { elemMatch: { slugCategory: { eq: $slugSektor } } } }
//     ) {
//       edges {
//         node {
//           title: titleSubCategory
//           sslug: slugSubCategory
//         }
//       }
//     }
//   }
// `;

// const subCategList = graphql`
// query getSubCategory($slugCategory: String!) {
//     subCateg: allContentfulCategory(filter: {slugCategory: {eq: $slugCategory}}) {
//       edges {
//         node {
//           subCategoryRef {
//             titleSubCategory
//             slugSubCategory
//           }
//         }
//       }
//     }
//   }
// `;

export default subTemplate;
